export enum Icon {
  Search = 'assets/media/metronic/icons/duotune/general/gen021.svg',
  Plus = 'assets/media/metronic/icons/duotune/arrows/arr075.svg',
  Funnel = 'assets/media/metronic/icons/duotune/general/gen031.svg',
  Mail = 'assets/media/metronic/icons/duotune/communication/com010.svg',
  ShieldLocked = 'assets/media/metronic/icons/duotune/general/gen051.svg',
  ExclRound = 'assets/media/metronic/icons/duotune/general/gen044.svg',
  Pencil = 'assets/media/metronic/icons/duotune/art/art005.svg',
  Trash = 'assets/media/metronic/icons/duotune/general/gen027.svg',
  Close = 'assets/media/metronic/icons/duotune/arrows/arr061.svg',
  arr061 = 'assets/media/metronic/icons/duotune/arrows/arr061.svg',
  arr085 = 'assets/media/metronic/icons/duotune/arrows/arr085.svg',
  arr097 = 'assets/media/metronic/icons/duotune/arrows/arr097.svg',
  arr009 = 'assets/media/metronic/icons/duotune/arrows/arr009.svg',
  arr028 = 'assets/media/metronic/icons/duotune/arrows/arr028.svg',
  custom_plus = 'assets/media/svg/icons/plus.svg',
  com008 = 'assets/media/metronic/icons/duotune/communication/com008.svg',
  gen041 = 'assets/media/metronic/icons/duotune/general/gen041.svg',
  gen010 = 'assets/media/metronic/icons/duotune/general/gen010.svg',
  gen045 = 'assets/media/metronic/icons/duotune/general/gen045.svg',
  gen044 = 'assets/media/metronic/icons/duotune/general/gen044.svg',
  gen040 = 'assets/media/metronic/icons/duotune/general/gen040.svg',
  gen002 = 'assets/media/metronic/icons/duotune/general/gen002.svg',
  gen043 = 'assets/media/metronic/icons/duotune/general/gen043.svg',
  gen030 = 'assets/media/metronic/icons/duotune/general/gen030.svg',
  fin009 = 'assets/media/metronic/icons/duotune/finance/fin009.svg',
  arr079 = 'assets/media/metronic/icons/duotune/arrows/arr079.svg',
  abs015 = 'assets/media/metronic/icons/duotune/abstract/abs015.svg',
  txt001 = 'assets/media/metronic/icons/duotune/text/txt001.svg',
  fil021 = 'assets/media/metronic/icons/duotune/files/fil021.svg',
  arrows_arr070 = 'assets/media/metronic/icons/duotune/arrows/arr070.svg',
  communication_com006 = 'assets/media/metronic/icons/duotune/communication/com006.svg',

  pause_fill = 'assets/media/bootstrap/svg/pause-fill.svg',
}
